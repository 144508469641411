<template>
  <div id="box">
    <!-- E6评估 结合的四个量表综合评估报告 -->
    <h1>光照疗效指标测验报告</h1>
    <h2>重庆市精神卫生中心</h2>
    <table v-if="this.update.cname">
      <!-- <div class="inputDeep">
        <el-input></el-input>
      </div> -->
      <tr>
        <td
          valign="center"
          rowspan="1"
          colspan="4"
          style="text-align: center"
          class="inputDeep"
        >
          姓名：{{ this.update.cname }}
        </td>
        <td valign="center" rowspan="1" colspan="4" style="text-align: center">
          患者ID：{{ this.update.cid }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="4" style="text-align: center">
          科室：{{ this.update.section }}
        </td>
        <td valign="center" rowspan="1" colspan="4" style="text-align: center">
          指导医生：{{ this.update.doctor }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="10" style="text-align: center">
          报告时间：{{ this.update.dateTime.replace("T", "-") }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          序号
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          项目
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          评分
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          参考诊断
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          1
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          抑郁水平
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.HAMD }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.HAMDText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          2
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          焦虑水平
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.BAI }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.BAIText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          3
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          睡眠质量
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_quality }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_qualityText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          4
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          睡眠障碍
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_disorders }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_disordersText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          5
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          睡眠觉醒类型
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.MEQ }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.MEQText }}
        </td>
      </tr>
      <tr>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          6
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          睡眠综合评估
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep }}
        </td>
        <td valign="center" rowspan="1" colspan="2" style="text-align: center">
          {{ this.Sleep_text }}
        </td>
      </tr>
    </table>

    <table id="table2">
      <div style="text-align: left" :v-model="update.review">
        <p>测验结论：{{ this.update.review }}</p>
      </div>
    </table>
    <table id="table3">
      <tr style="height: 40px">
        <td
          valign="left"
          rowspan="1"
          colspan="4"
          style="text-align: rigth; border: none"
        >
          _______年_______月_______日填
        </td>
        <td
          valign="center"
          rowspan="1"
          colspan="4"
          style="text-align: right; border: none"
        >
          签字：________<br />
        </td>
      </tr>
    </table>

    <p style="text-align:left;color:black">当前时间：{{ this.updateTime }}</p>
    <!-- <el-button
      type="primary"
      @click="$router.push(`/ReportUpdate/${update.cname}/${update.id}`)"
      >修改报告</el-button
    > -->
  </div>
</template>
<script>
import api from "../../http/api";
export default {
  name: "Dashboards",
  data() {
    return {
      update: {},
      cname: "",
      CID: "",
      updateTime: "",
    };
  },
  created() {
    this.getOneReport();
    // console.log("update", this.update);
  },
  updated() {
    // console.log("结论", this.update.review);
    // console.log("姓名", this.cname);
  },
  mounted() {
    this.addDate();
    // console.log(11111111111, this.$route.params);
  },

  computed: {
    cid() {
      return this.$route.params.cid;
    },
    id() {
      return this.$route.params.id;
    },
    // 睡眠觉醒类型
    MEQ() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        if (data) {
          var arr = data.split(",");
          let meq = arr.map(Number)[2];
          return meq;
        }
      } else {
        return "/";
      }
    },
    // 睡眠综合评分
    Sleep() {
      if (this.update.etId !== "E6") {
        return "你还没有做该项评估";
      } else {
        return this.update.score;
      }
    },
    Sleep_text() {
      if (this.update.etId !== "E6") {
        return "你还没有做该项评估";
      } else {
        return this.update.solution;
      }
    },
    // 焦虑评估
    BAI() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        if (data) {
          var arr = data.split(",");
          let bai = arr.map(Number)[3];
          return bai;
        }
      } else {
        return "/";
      }
    },
    // 抑郁评估
    HAMD() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        if (data) {
          var arr = data.split(",");
          let hamd = arr.map(Number)[4];
          return hamd;
        }
      } else {
        return "/";
      }
    },
    // 睡眠质量
    Sleep_quality() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        if (data) {
          var arr = data.split(",");
          let a = arr.map(Number)[0];
          return a;
        }
      } else {
        return "/";
      }
    },
    // 睡眠障碍
    Sleep_disorders() {
      if (this.update.data !== "暂无") {
        let data = this.update.data;
        if (data) {
          var arr = data.split(",");
          let a = arr.map(Number)[1];
          return a;
        }
      } else {
        return "/";
      }
    },
    // 睡眠觉醒类型诊断参考
    MEQText() {
      if (this.MEQ < 23) {
        return "/";
      }
      if (this.MEQ >= 23 && this.MEQ <= 26) {
        return "绝对 “夜晚”型";
      }
      if (this.MEQ >= 27 && this.MEQ <= 30) {
        return "绝对 “夜晚”型";
      }
      if (this.MEQ >= 31 && this.MEQ <= 34) {
        return "中度 “夜晚”型";
      }
      if (this.MEQ >= 35 && this.MEQ <= 38) {
        return "中度 “夜晚”型";
      }
      if (this.MEQ >= 39 && this.MEQ <= 41) {
        return "中度 “夜晚”型";
      }
      if (this.MEQ >= 42 && this.MEQ <= 45) {
        return "中间型";
      }
      if (this.MEQ >= 46 && this.MEQ <= 49) {
        return "中间型";
      }
      if (this.MEQ >= 50 && this.MEQ <= 53) {
        return "中间型";
      }
      if (this.MEQ >= 54 && this.MEQ <= 57) {
        return "中间型";
      }
      if (this.MEQ >= 58 && this.MEQ <= 61) {
        return "中度 “清晨”型";
      }
      if (this.MEQ >= 62 && this.MEQ <= 65) {
        return "中度 “清晨”型";
      }
      if (this.MEQ >= 66 && this.MEQ <= 69) {
        return "中度 “清晨”型";
      }
      if (this.MEQ >= 70 && this.MEQ <= 72) {
        return "绝对 “清晨”型";
      }
      if (this.MEQ >= 72 && this.MEQ <= 86) {
        return "中度 “清晨”型";
      }
    },
    BAIText() {
      if (this.BAI < 15) {
        return "无焦虑";
      }
      if (this.BAI >= 15 && this.BAI <= 25) {
        return "轻度焦虑";
      }
      if (this.BAI > 26 && this.BAI <= 35) {
        return "中度焦虑";
      }
      if (this.BAI >= 36) {
        return "重度焦虑";
      }
    },
    HAMDText() {
      if (this.HAMD < 8) {
        return "正常";
      }
      if (this.HAMD >= 8 && this.HAMD <= 20) {
        return "轻度";
      }
      if (this.HAMD > 20 && this.HAMD <= 35) {
        return "中度";
      }
      if (this.HAMD > 35) {
        return "重度";
      }
    },
    Sleep_qualityText() {
      if (this.Sleep_quality == 0) {
        return "很好";
      }
      if (this.Sleep_quality == 1) {
        return "较好";
      }
      if (this.Sleep_quality == 2) {
        return "较差";
      }
      if (this.Sleep_quality == 3) {
        return "很差";
      }
    },
    Sleep_disordersText() {
      if (this.Sleep_disorders == 0) {
        return "无";
      }
      if (this.Sleep_disorders == 1) {
        return "轻度";
      }
      if (this.Sleep_disorders == 2) {
        return "中度";
      }
      if (this.Sleep_disorders == 3) {
        return "重度";
      }
    },
  },

  methods: {
    async getOneReport(cid, id) {
      this.dialogVisible2 = true;
      const data = await api.patient.lookRportOne(this.cid, this.id);
      if (data.status == 200) {
        this.update = data.data.data[0];
      }
    },
    //获取当前年月日
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month > 10 ? date.month : "0" + date.month;
      const day = date.date > 10 ? date.date : "0" + date.date;
      this.updateTime = date.year + "-" + newmonth + "-" + day;
    },
  },
};
</script>

<style lang="scss" scoped>
#box {
  width: 800px;
  margin: auto;
}
h1 {
  color: black;
}
h2 {
  color: black;
}
table {
  border: 1px solid black;
  border-collapse: collapse;
  margin: 0 auto;
  width: 800px;
}
table td,
th {
  border: 1px black solid;
  background-color: white;
  height: 40px;
  width: 100px;
  color: black;
}
#table2 {
  width: 798;
  height: 200px;
  border-top: none;
  div {
    width: 798;
    height: 200px;
    p {
      // padding: 0;
      margin: 0px;
      padding: 20px 20px;
      color: black;
    }
  }
}
#table3 {
  border-top: none;
  // border-right: none;
}
.el-input {
  width: 100px;
}
/* 利用穿透，设置input边框隐藏 */
// .inputDeep >>> .el-input__inner {
//   border: 0;
// }
</style>
